import { graphql } from "gatsby"
import dayjs from 'dayjs'
import { ResearchTaskModel } from "./researchTask"

export interface Block {
    children: {
        "_type": string;
        text: string;
    }[]
}
export interface Event {
    name: string;
    dateStart: string;
    dateEnd: string;
    slug: {
        current: string;
    }
    summary: string;
    bonuses: {
        description: string;
        type: {
            title: string;
            icon: {
                asset: any;
            }
        }
    }[]
    researchTasks?: ResearchTaskModel[]
    _rawIntro: {
        children: Block[];
    }
    _rawBody: {
        children: Block[];
    }
    image: {
       asset: {
           fluid: any;
       }
    }
}

export interface EventNode {
    node: Event;
}

export const sortEventsByDate = (a: Event, b: Event) => dayjs(a.dateEnd).diff(dayjs(b.dateEnd))

export const EVENT_CORE_FIELDS = graphql`
    fragment EventFields on SanityEvent {
        name
        dateStart
        dateEnd
        slug {
          current
        }
        summary
        _rawIntro
        _rawBody
        image {
            asset {
                fluid(maxWidth: 360) {
                    ...GatsbySanityImageFluid
                }
            }
        }
        thumbnail {
            asset {
                fixed(height: 125, width: 125) {
                    ...GatsbySanityImageFixed
                }
            }
        }
        bonuses {
            description
            type {
                title
                icon {
                    asset {
                        fluid(maxWidth: 50) {
                            ...GatsbySanityImageFluid
                        }
                    }
                }
            }
            pokemon {
                pokemonId
                shiny
                costume
            }
            tasks {
                name
                reward {
                    pokemonId
                    shiny
                    costume
                }
            }
        }
    }
`