import React, { FC } from "react"
import Img from "gatsby-image"
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime' // import plugin

dayjs.extend(relativeTime)

import Box from "../box"
import Card from "../card"
import Link from "../link"
import { EventLayout, EventInfo, EventBonuses, EventProps } from "./event-preview"

interface Props {
    event: any;
    isOngoing?: boolean;
    layout?: EventLayout;
}


const EventPreviewMedium: FC<Props> = ({ event, isOngoing, layout = EventLayout.Medium }) => {
    return (
        <Card my={4} variant={'none'}>
                <Box display="flex" justifyContent="space-between">
                    <Box py={4} pr={3} flex="2">
                        <EventInfo event={event} isOngoing={isOngoing} layout={layout} />
                    </Box>
                    {event.thumbnail &&
                        <Box width="125px" height="125px" overflow="hidden" borderRadius="medium">
                            <Img fixed={event.thumbnail.asset.fixed} />
                        </Box>
                    }
                </Box>
        </Card>
    )
}

export default EventPreviewMedium