import React, { FC } from "react"
import Img from "gatsby-image"

import Card from "../card"
import Text from "../text"
import Box from "../box"
import { PokemonReference } from "../../models/pokemon"
import PokemonPreview from "../pokemon/pokemon-preview"

interface Props {
    bonus: any;
    isCompressed?: boolean;
}

const EventBonus: FC<Props> = ({ bonus, isCompressed = true }) => {
    const hasExtraContent = !isCompressed && (bonus.description || bonus.pokemon.length)
    return (
        <Card variant="flat" p={3} mb={3} flex="1" display="flex" alignItems={hasExtraContent ? 'flex-start' : 'center'} backgroundColor="grey.1" borderColor="grey.2">
            {bonus.type.icon && <Box width="35px" height="auto" flex="0 0 35px"><Img fluid={bonus.type.icon.asset.fluid} /></Box>}
            <Box>
                <Text m={0} ml={4} fontSize={3} fontWeight="bold">{bonus.type.title}</Text>
                {!isCompressed && <Text m={0} ml={4} fontSize={2}>{bonus.description}</Text>}
                {!isCompressed && <Box display="flex" flexWrap="wrap">
                    {bonus.pokemon.map((pokemon: PokemonReference) => (
                        <PokemonPreview pokemonRef={pokemon} bg="grey.1" borderColor="grey.2" imageSize="50px" iconSize="10px" mr={3} p={2} />
                    ))}
                </Box>}
            </Box>
        </Card>
    )
}

export default EventBonus