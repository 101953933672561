import React, { FC, useEffect, useState } from "react"
import dayjs from 'dayjs'
import SwipeableViews from 'react-swipeable-views';

import SEO from "../components/seo"

import Heading from "../components/heading"
import Text from "../components/text"
import Link from "../components/link"
import Button from "../components/button"
import Card from "../components/card"
import PokemonCards from "../components/pokemon-cards"
import { graphql, PageProps } from "gatsby"
import Box from "../components/box";
import EventPreview, { EventLayout } from "../components/event/event-preview";
import Expand from "../images/icons/expand"
import Compress from "../images/icons/compress"
import { Event as EventModel, EventNode, sortEventsByDate } from "../models/event"
import Filter from "../images/icons/filter"
import Wrapper from "../containers/wrapper"
import Tabs from "../components/tab/tabs";
import Tab from "../components/tab/tab";

interface Props extends PageProps {
  data: {
    allSanityEvent: {
      edges: EventNode[]
    }
  }
}

const IndexPage: FC<Props> = ({ data, params }) => {
  const [isCompact, setIsCompact] = useState(false)
  const [todayEvents, setTodayEvents] = useState<Array<EventModel>>([])
  const [currentEvents, setCurrentEvents] = useState<Array<EventModel>>([])
  const [upcomingEvents, setUpcomingEvents] = useState<Array<EventModel>>([])
  const [viewIndex, setViewIndex] = useState(0)

  const { allSanityEvent: { edges: events } } = data;

  useEffect(() => {
    const todays: EventModel[] = [], current: EventModel[] = [], upcoming: EventModel[] = []

    events.forEach(({ node: event }) => {
      const today = dayjs()
      const startDate = dayjs(event.dateStart)
      const endDate = dayjs(event.dateEnd)
      const hasStarted = dayjs().diff(startDate) > 0
      const hasEnded = dayjs().diff(endDate) > 0

      if (startDate.isSame(today, 'day') && endDate.isSame(today, 'day') && !hasEnded) {
        // Event is today only and has not ended
        todays.push(event)
      } else if (hasStarted && !hasEnded) {
        current.push(event)
      } else if (!hasStarted && !hasEnded) {
        upcoming.push(event)
      }
    })
    setTodayEvents(todays)
    setCurrentEvents(current)
    setUpcomingEvents(upcoming)
  }, [data])

  const toggleCompact = () => {
    setIsCompact(!isCompact)
  }

  const onChangeView = (index: number) => {
    setViewIndex(index)
  }

  return (
    <Wrapper>
      <SEO title={`Pokemon Go`} />

      {/* Current events */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Text fontSize={4} mb={2} lineHeight="1" color="grey.5">{dayjs().format('dddd D')}</Text>
          <Heading as="h1" fontSize={8} mt={0} mb={4}>Events</Heading>
        </Box>
        <Box display="flex">
          <Button variant={isCompact ? 'filterSelected' : 'filter'} onClick={() => toggleCompact()} mr={3} px={4}>
            {isCompact ? <Box display="flex" alignItems="center">
              <Expand width="10px" fill="primary" />
              <Text m={0} ml={2} fontSize={0}>Compressed</Text>
            </Box> : <Box display="flex" alignItems="center">
                <Compress width="10px" fill="primary" />
                <Text m={0} ml={2} fontSize={0}>Expanded</Text>
              </Box>
            }
          </Button>
          <Button variant="filterSelected" onClick={() => true} px={6} aria-label="Filter events">
            <Filter width="10px" fill="primary" />
          </Button>
        </Box>
      </Box>

      <Tabs index={viewIndex} onChangeIndex={(newIndex) => setViewIndex(newIndex)}>
        <Tab><Heading as="h2" fontSize={2}>Current Events</Heading></Tab>
        <Tab><Heading as="h2" fontSize={2}>Upcoming Events</Heading></Tab>
      </Tabs>

      <SwipeableViews index={viewIndex} onChangeIndex={onChangeView}>
        <Box>
          {todayEvents.length > 0 && <Heading as="h2" fontSize={6} mt={5} mb={4}>Starting today</Heading>}
          {todayEvents.sort(sortEventsByDate).map((event, index) => (
            <EventPreview
              key={`${event.slug.current}`}
              event={event}
              layout={!isCompact ? EventLayout.Large : EventLayout.Small}
              isOngoing={dayjs().diff(event.dateStart) > 0 ? true : false} />
          ))}

          {currentEvents.sort(sortEventsByDate).map((event, index) => (
            <EventPreview
              key={`${event.slug.current}`}
              event={event}
              layout={!isCompact ? index === 0 ? EventLayout.Large : EventLayout.Medium : EventLayout.Small}
              isOngoing={true} />
          ))}
        </Box>

        <Box>
          {upcomingEvents.sort(sortEventsByDate).map(event => (
            <EventPreview key={`${event.slug.current}`} event={event} layout={isCompact ? EventLayout.Small : EventLayout.Medium} />
          ))}
        </Box>
      </SwipeableViews>

    </Wrapper >
  )
}

export const query = graphql`
  query {
    allSanityEvent(
    sort: {fields: dateStart}
    filter: {dateStart: {gt: "2021-03-01T17:00:00.000Z"}}
  ) {
      edges {
        node {
          ...EventFields
        }
      }
    }
  }
`

export default IndexPage
