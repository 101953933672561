import React, { FC, SVGAttributes } from "react"
import SVG from "./svg"

const Hat: FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <SVG
      viewBox="0 0 33 55"
      {...props}
    >
      <path
        d="M20.537 17.353L21 15.5l2 .5-.5-5.5L25 9l-2-2V5.5l1.5-1-2-.5V2l-2 1h-1L18 0l-1 3h-2l-2.5-1.5v4l-2 .5L12 8.5v2L9.5 13h3l.986 1.478L7.45 31.45l13.02-13.962.029.012.015-.06.038-.04-.016-.047zM33 55v-2H0v2h33zM24.188 27.623L2.388 51H.5l3.892-10.945 17.532-18.801 2.264 6.37zM27.891 38.037L15.803 51H7.6l18.027-19.332 2.265 6.37zM32.5 51H20.194l8.91-9.554L32.5 51z"
      ></path>
    </SVG>
  )
}

export default Hat
