import React from "react"

const Expand = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M300 224h136c10.7 0 16-12.9 8.4-20.5l-50.9-51L507.3 38.6c6.2-6.2 6.2-16.4 0-22.6L496 4.7c-6.2-6.2-16.4-6.2-22.6 0L359.5 118.6l-51-51C300.9 60 288 65.3 288 76v136c0 6.6 5.4 12 12 12zm93.4 135.5l51-51c7.5-7.6 2.2-20.5-8.5-20.5H300c-6.6 0-12 5.4-12 12v136c0 10.7 12.9 16 20.5 8.4l51-50.9 113.9 113.9c6.2 6.2 16.4 6.2 22.6 0l11.3-11.3c6.2-6.2 6.2-16.4 0-22.6l-113.9-114zM212 288H76c-10.7 0-16 12.9-8.4 20.5l50.9 51L4.7 473.4c-6.2 6.2-6.2 16.4 0 22.6L16 507.3c6.2 6.2 16.4 6.2 22.6 0l113.9-113.9 51 51c7.6 7.5 20.5 2.2 20.5-8.5V300c0-6.6-5.4-12-12-12zm-93.4-135.5l-51 51C60 211.1 65.3 224 76 224h136c6.6 0 12-5.4 12-12V76c0-10.7-12.9-16-20.5-8.4l-51 50.9L38.6 4.7c-6.2-6.2-16.4-6.2-22.6 0L4.7 16c-6.2 6.2-6.2 16.4 0 22.6l113.9 113.9z"></path>
    </svg>
  )
}

export default Expand
