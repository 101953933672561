import React, { FC, ReactNode, useContext } from "react";
import Box, { BoxProps } from "../box";
import { TabsContext } from "./tabs";
import Button from "../button"

interface Props extends BoxProps {
    children: ReactNode;
    index?: number;
}



const Tab: FC<Props> = ({ children, index = 0, ...rest }) => {
    const { selectedIndex, setSelectedIndex} = useContext(TabsContext)

    return (
        <Box flex="1" {...rest}>
            <Button py={3} fontSize={2} textAlign="center" variant="none" onClick={() => setSelectedIndex(index)}>{children}</Button>
        </Box>
    )
}

export default Tab