import React, { FC } from "react"
import Img from "gatsby-image"
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime' // import plugin

dayjs.extend(relativeTime)

import Box from "../box"
import Card from "../card"
import Link from "../link"
import { EventLayout, EventInfo, EventBonuses, EventProps } from "./event-preview"

interface Props {
    event: any;
    isOngoing?: boolean;
    layout?: EventLayout;
}

const EventPreviewLarge: FC<Props> = ({ event, isOngoing, layout = EventLayout.Large }) => {
    return (
        <Card my={4} variant={'default'}>
            {event.image && <Img fluid={event.image.asset.fluid} />}
            <Box p={4}>
                <EventInfo event={event} isOngoing={isOngoing} />
                <EventBonuses event={event} />
            </Box>
        </Card>
    )
}

export default EventPreviewLarge