import React, { FC } from "react"
import Img from "gatsby-image"
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime' // import plugin

dayjs.extend(relativeTime)

import Box from "../box"
import Card from "../card"
import Link from "../link"
import { EventLayout, EventInfo, EventBonuses, EventProps } from "./event-preview"

const EventPreviewSmall: FC<EventProps> = ({ event, isOngoing, layout = EventLayout.Small }) => {
    return (
        <Card my={4} variant={'default'}>
            <Box p={4}>
                <EventInfo event={event} isOngoing={isOngoing} />
            </Box>
        </Card>
    )
}

export default EventPreviewSmall