import React, { FC } from 'react'
import styled from "styled-components"
import { space } from "styled-system"
import Img from 'gatsby-image'

import Box, { BoxProps } from '../box'
import { PokemonForm, PokemonReference } from '../../models/pokemon'
import { useAllPokemon } from '../../hooks/useAllPokemon'
import Text from '../text'
import Sparkle from '../../images/icons/sparkle'
import Hat from '../../images/icons/hat'
import Shadow from '../../images/icons/shadow'

interface Props extends BoxProps {
    pokemonRef: PokemonReference;
    imageSize?: string;
    iconSize?: string;
    hideName?: boolean;
}

export const PokemonContainer = styled(Box)`
    border-radius: ${({ theme }) => theme.radii.small}px;
    border-style: solid;
    border-width: 1px;
    padding: ${({theme}) => theme.space[3]}px;
    ${space}
`

const PokemonPreview: FC<Props> = ({ pokemonRef, imageSize = "80px", iconSize = "15px", hideName, mr, ...rest }) => {
    const allPokemon = useAllPokemon()
    const { shiny, pokemonId, costume, form = PokemonForm.Normal } = pokemonRef
    const { node: pokemon } = allPokemon[pokemonId - 1]
    const formInfo = form === PokemonForm.Shadow ? pokemon.forms['normal'] : pokemon.forms[form]
    const image = formInfo.image ? formInfo.image.fluid : null

    return (
        <Box
            display="inline-flex"
            flexDirection = "column"
            alignItems="center"
            mr={mr}
        >
            <PokemonContainer position="relative" bg="grey.0" borderColor="grey.1" {...rest}>
                <Box
                    height={imageSize}
                    width={imageSize}
                >
                    {image && <Img fluid={image} />}
                </Box>
                {shiny && <Box
                    position="absolute"
                    top="7%"
                    right="7%"
                    display="flex"
                >
                    <Sparkle fill="black" height={iconSize} />
                </Box>}
                {costume && <Box
                    position="absolute"
                    top="7%"
                    left="7%"
                    display="flex"
                >
                    <Hat fill="black" height={iconSize} />
                </Box>}
                {form === PokemonForm.Shadow && <Box
                    position="absolute"
                    bottom="7%"
                    left="7%"
                    display="flex"
                >
                    <Shadow fill="#C39EEA" height={iconSize} />
                </Box>}
            </PokemonContainer>
            {!hideName && <Text fontSize={1} mt={2} mb={2}>{pokemon.name}</Text>}
        </Box>
    )
}

export default PokemonPreview