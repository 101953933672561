import React, { FC, SVGAttributes } from "react"
import SVG from "./svg"

const Shadow: FC<SVGAttributes<SVGElement>> = (props) => {
  return (
    <SVG
      viewBox="0 0 39 44"
      {...props}
    >
      <path
        d="M34 26c0 4.4-9.5 16-11.5 16-4 .5-7.5.5-11 0-3.5 0-9-5-8.5-7.5 1.5 1 7 4 8 2-3.5-1-4-4.5-4-4.5S4.758 21.738 7 18c1.5 1 .948 3 4 3 .5 0 1.834-2 2-3V6c0-4.5 6.5-6.5 5-5.5-1.2.8-2 4.5-1 5s7.5 5 8 6.5 0 1 0 4 0 9 4 9c-2-3.5-.666-5.167-2-7 1.5-1 7 3.6 7 8zM35.5 40C33 42.5 31 41.5 29 44c.5-3.5.5-6 3-7.5s2.5-.5 6-5.5c.5 4 0 6.5-2.5 9zM9 7c-2 2-3 4-3 4-.5 3 1 2.5 1 4.5s-1.5 1.5-1.5 5C2.61 20.5.9 17.47.48 15-.174 11.164 5.334 6.084 9 7z"
      ></path>
    </SVG>
  )
}

export default Shadow
