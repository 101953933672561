import React, { Dispatch, FC, ReactNode, SetStateAction, useEffect, useState } from "react";
import Box, { BoxProps } from "../box";

interface Props extends BoxProps {
    children: ReactNode;
    index: number;
    onChangeIndex: (index: number) => void
}

interface ContextProps {
    selectedIndex: number;
    setSelectedIndex: Dispatch<SetStateAction<number>>
}

export const TabsContext = React.createContext<ContextProps>({
    selectedIndex: 0,
    setSelectedIndex: () => {}
});

const Tabs: FC<Props> = ({ children, index, onChangeIndex, ...rest }) => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    
    useEffect(() => {
        onChangeIndex(selectedIndex)
    }, [selectedIndex])

    const tabItems = React.Children.toArray(children)
    return (
        <TabsContext.Provider value={{selectedIndex, setSelectedIndex}}>
            <Box backgroundColor="grey.1" position="relative" {...rest}>
                <Box display="flex" alignItems="center">
                    {tabItems.map((item: any, index) => (
                        React.cloneElement(item, { index })
                    ))}
                </Box>
                <Box
                    position="absolute"
                    height="1px"
                    width={`${100 / tabItems.length}%`}
                    left="0"
                    bottom="0"
                    backgroundColor="primary"
                    style={{ transform: `translateX(${100 * index}%)`, transformOrigin: 'right center', transition: 'transform .35s ease-out' }}
                />
            </Box>
        </TabsContext.Provider>
    )
}

export default Tabs