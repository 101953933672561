import React, { FC } from "react"
import Img from "gatsby-image"
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime' // import plugin

dayjs.extend(relativeTime)

import Box from "../box"
import Card from "../card"
import Heading from "../heading"
import Link from "../link"
import Text from "../text"
import EventBonus from "./event-bonus"
import EventPreviewSmall from "./event-preview-small"
import EventPreviewMedium from "./event-preview-medium"
import EventPreviewLarge from "./event-preview-large"

export enum EventLayout {
    Large,
    Medium,
    Small,
}

export interface EventProps {
    event: any;
    isOngoing?: boolean;
    layout?: EventLayout;
}

export const EventInfo: FC<EventProps> = ({ event, isOngoing, layout }) => (
    <Box display={layout === EventLayout.Medium ? 'block' : 'flex'} alignItems="center">
        {!isOngoing &&
            <Text fontSize={1} m={0} mb={2} color="grey.5">Starts {dayjs(event.dateStart).fromNow()}</Text>
        }
        <Box flexBasis="75%">
            <Heading as="h2" fontSize={4} m={0} mb={layout === EventLayout.Medium ? 2 : 0}>
                <Link to={`/event/${event.slug.current}`} fontSize="inherit">
                    {event.name}
                </Link>
            </Heading>
            {isOngoing ?
                <Text fontSize={2} m={0} color="grey.5">Ends: {dayjs(event.dateEnd).format("ddd, MMM D, h:mm A")}</Text>
                :
                <Text fontSize={2} m={0} color="grey.5">Starts: {dayjs(event.dateStart).format("ddd, MMM D, h:mm A")}</Text>
            }
        </Box>
        {isOngoing &&
            <Text fontSize={1} m={0} color="grey.5">Ends {dayjs(event.dateEnd).fromNow()}</Text>
        }
        {layout === EventLayout.Medium && <Text fontSize={1} color="black" mt={2}>{event.summary}</Text>}
    </Box>
)

export const EventBonuses: FC<EventProps> = ({ event }) => {
    return event.bonuses?.length > 0 ? <Box mt={4}>
        <Heading as="h3" fontSize={3} mb={3}>Bonuses</Heading>
        {event.bonuses.map((bonus: any, index: number) => (
            <EventBonus key={`${event.slug.current}-bonus-${index}`} bonus={bonus} />
        ))}
    </Box> : null
}

const EventPreview: FC<EventProps> = ({ event, isOngoing, layout = EventLayout.Medium }) => {
    let Event;
    switch (layout) {
        case EventLayout.Large:
            Event = EventPreviewLarge
            break
        case EventLayout.Medium:
            Event = EventPreviewMedium
            break
        case EventLayout.Small:
            Event = EventPreviewSmall
            break
    }
    return (
        <Event event={event} isOngoing={isOngoing} layout={layout} />
    )
}

export default EventPreview